import { SEOData } from "utils/cms/pageHelper";
import Index from "../index";

interface Props {
  finalSEO?: SEOData;
}
const finalSEO = {
  title: "Absolutely Craveable Chicken, Zalads & Zappetizers | Zaxbys",
  description: "Absolutely craveable, daringly zesty, made-to-order chicken fingers, wings and more. This is gonna be good.",
};

const Home = ({ finalSEO: any }: Props) => {
  return <Index finalSEO={finalSEO} />;
};

export default Home;
